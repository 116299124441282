import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import session from './session'
import matchReducer from "./match"
import chatReducer from "./chat"
import friendRequestReducer from "./friendrequest"
import historyReducer from "./history"
import userReducer from "./user"


const rootReducer = combineReducers({
  session,
  match: matchReducer,
  chat: chatReducer,
  friendrequest: friendRequestReducer,
  history: historyReducer,
  user: userReducer
});


let enhancer;

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(thunk);
} else {
  const logger = require('redux-logger').default;
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
  return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
